@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.btn {
    transition-duration: none !important;
}

.btn-primary {
    background-color: #3a8182 !important;
    border: none;
}

.btn-primary:hover {
    filter: brightness(110%);
}

.btn-secondary {
    background-color: #4b4bff !important;
    border: none;
}

.btn-secondary:hover {
    filter: brightness(110%);
}

.btn-active {
    background-color: #3a8182 !important;
    border: 1px solid black !important;
}

.btn-active:hover {
    filter: brightness(110%);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (prefers-color-scheme: dark) {
    .btn-primary {
        background-color: #1d4d4d !important;
        border: none;
    }

    .btn-primary:hover {
        filter: brightness(110%);
    }

    .btn-active {
        background-color: #1d4d4d !important;
        border: 1px solid white !important;
    }

    .btn-active:hover {
        filter: brightness(110%);
    }

    .rdtPicker {
        background-color: hsla(219 14.1% 27.8% / 1) !important;
        box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1);
        border: 1px solid hsla(219 14.1% 27.8% / 0.8);
    }
    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
        background-color: hsla(219 14.1% 17.8% / 1);
        cursor: pointer;
    }
    td.rdtMonth:hover,
    td.rdtYear:hover {
        background-color: hsla(219 14.1% 17.8% / 1);
        cursor: pointer;
    }
    .rdtPicker thead tr:first-of-type th:hover {
        background-color: hsla(219 14.1% 17.8% / 1);
    }
    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
        color: #777;
    }
    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
        background-color: #4e9d9f !important;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    .MuiInputBase-input2 {
        color: white;
    }
}



/* OUTLINED INPUT STYLES */
/* 
.outlined-input {
    position: relative;
}

.outlined-input input {
    color: var(--main-white);
    height: 2rem;
    outline: 1px solid var(--main-grey);
    border-radius: 5px;
    border: none;
    background-color: var(--main-dark-grey);
    padding-left: 20px;
    font-size: 20px;
    transition: 0.2s ease-in-out;
}

.outlined-input label {
    position: absolute;
    top: 120%;
    left: 5%;
    transform: translateY(-50%);
    background-color: var(--main-dark-grey);
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    transition: 0.2s ease-in-out;
    font-size: 1rem;
    pointer-events: none;
    padding: 0 5px;
}

.outlined-input input:not(:placeholder-shown) ~ label,
.outlined-input input:focus ~ label {
    top: 0px;
    left: 10px;
    font-size: 1rem;
}

.outlined-input input:focus {
    outline: 1px solid var(--main-blue) !important;
}
.outlined-input input:hover {
    outline: 1px solid var(--main-white);
}
.outlined-input input:focus ~ label {
    color: var(--main-blue) !important;
} */

/* END OUTLINED INPUT STYLES */



/* STANDARD INPUT STYLES */

.standard-input {
    position: relative;
    margin-top: 0.75rem;
    margin-bottom: 0.375rem;
}

.standard-input input {
    color: var(--main-white);
    height: 3rem;
    border: none;
    /* border-bottom: 1px solid var(--main-grey); */
    background-color: inherit;
    /* padding-left: 20px; */
    font-size: 16px;
    transition: .1s ease-in-out;
    outline: none;
    box-sizing: border-box;
}

.standard-input label {
    position: absolute;
    top: 50%;
    left: -0.25rem;
    transform: translateY(-50%);
    background-color: inherit;
    color: inherit;
    text-align: center;
    transition: .1s ease-in-out;
    font-size: 1rem;
    pointer-events: none;
    padding: 0 5px;
}

.standard-input input:not(:placeholder-shown) ~ label,
.standard-input input:focus ~ label {
    top: 0px;
    left: -4px;
    font-size: 1rem;
}

.standard-input .underline {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
    /* border-bottom: 2px solid var(--main-blue); */
}

.standard-input input:focus ~ .underline {
    transform: scaleX(1);
}
.standard-input input:hover {
    border-bottom: 2px solid var(--main-white);
}
.standard-input input:focus ~ label {
    color: var(--main-blue) !important;
}

/* END OF STANDARD INPUT STYLES */