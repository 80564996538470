@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        border-radius: 4px;
    }
}
.detail-card {
    border: 1px solid lightgrey;
    background-color: white;
    padding: 16px;
    border-radius: 4px;
}

@media (prefers-color-scheme: dark) {
    .detail-card {
        background-color: hsl(var(--b1));
        color: rgb(249 250 251);
    }
}

.detail-card > .card-title {

}

.detail-card .card-body {
    padding: 0;
    display: flex;
    flex-wrap: wrap
}

.detail-card .content-item {
    display: flex;
    flex-grow:calc(100%);
    width: 100%;
    min-width: 400px;
    max-width: 600px;
}