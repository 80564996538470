@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .card {
        border-radius: 4px;
    }

    .table th:first-child {
        position: sticky;
        position: -webkit-sticky;
        left: 0;
        z-index: 0 !important;
    }

    .table-scrollable-card {
        flex: 1;
        overflow: scroll !important;
        z-index: 10;
    }
    .table-scrollable {
        table-layout: auto;
        position: relative;
        background-color: white;
    }
    .table-scrollable > thead > tr:first-of-type {
        /* backgroundColor: rgb(100,100,100); */
        z-index: 30;
        top: 0;
        padding: 12px;
        word-break: break-all;
        position: sticky;
        position: -webkit-sticky;
    }
    .table-scrollable > thead > tr > th {
        /* backkgroundColor: rgb(100,100,100); */
        /* z-index: 60; */
        top: 0;
        padding: 12px;
        word-break: break-all;
        position: sticky;
        position: -webkit-sticky;
    }
    .table-scrollable > thead > tr > th:first-child {
        /* backkgroundColor: rgb(100,100,100); */
        z-index: 30;
        border-radius: 0;
        left: 0;
    }
    .table-scrollable > thead > tr > th:last-child {
        border-radius: 0;
    }

    .table-scrollable > tbody {
        overflow-x: scroll;
        font-size: 0.9rem;
    }
    .table-scrollable > tbody > tr > td:first-child {
        position: sticky;
        position: -webkit-sticky;
        left: 0;
        z-index: 20;
    }
    .table-scrollable > tbody > tr > td {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgba(200, 200, 200, 0.7);
    }
    .table-scrollable > tbody > tr > td.modified-cell {
        background-color: #fef08a;
    }
    .table-scrollable > tbody > tr > td > a {
        text-decoration: underline;
    }
}
