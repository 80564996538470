.MuiTooltip-tooltip {
    font-size: 1rem !important;
}

.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}

@media (prefers-color-scheme: dark) {
    .MuiPaper-root {
        background-color: transparent !important;
    }
    .MuiDrawer-root,
    .MuiDrawer-paper,
    .MuiList-root {
        background-color: hsla(219 14.1% 27.8% / 1) !important;
    }

    .Mui-disabled,
    .MuiFormLabel-root,
    .MuiIconButton-root,
    .MuiListItem-root {
        color: rgba(255, 255, 255, 0.87) !important;
    }
    .MuiInput-underline {
        border-bottom: 1px solid rgba(255, 255, 255, 0.42);
    }
}
