@tailwind base;
@tailwind components;
@tailwind utilities;

.admin-layout-container {
    width: 100%;
    padding: 4px;
    padding-top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
}
@media (prefers-color-scheme: dark) {
    .react-select-container .react-select__control {
        background-color: var(--bg-primary);
        border-color: var(--border-color);
    }
    .react-select-container .react-select__control--is-focused {
        background-color: rgb(30 41 59);
        color: var(--text-secondary);
    }
    .react-select-container .react-select__menu {
        background-color: hsla(var(--b1) / 1);
        border-color: var(--border-color);
    }
    .react-select-container .react-select__option {
        background-color: var(--bg-primary);
    }
    .react-select-container .react-select__option:hover {
        background-color: rgb(30 41 59);
        color: var(--text-secondary);
    }
    .react-select-container .react-select__indicator-separator {
        background-color: var(--border-color);
    }
    .react-select-container .react-select__input-container,
    .react-select-container .react-select__placeholder,
    .react-select-container .react-select__single-value {
        color: var(--text-primary);
    }
}
